<template>
  <div class="bg">
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <!-- <div class="text-center">
            <b-card-title class="mb-1">
              Welcome to LOTTO THE MA5 👋
            </b-card-title>
          </div> -->
          <!-- <div class="text-center mb-1">
            <img
              src="/Infinity_World_21.png"
              alt="logo"
              height="150px"
            >
          </div> -->

          <!-- form -->
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group>
                <label for="username">ชื่อผู้ใช้งาน หรือ เบอร์โทรศัพท์</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">รหัสผ่าน</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder=""
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  <span class="text-white">จดจำฉันไว้ในระบบ</span>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                เข้าสู่ระบบ
              </b-button>
            </b-form>
          </validation-observer>

          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  // BImg,
  // BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCard,
  // BCardTitle,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    // BImg,
    // BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      LottoList: [
        { text: 'กรุณาเลือกหวย', value: null, SubList: null },
      ],
      // THG :ผลสลากกินแบ่งรัฐบาล , GSB : ออมสิน, BAAC : ธกส,
      // STH : หุ้นไทย, HN : หวยฮานอย,
      // HNRB : หวยฮานอย แดงดำ, VT : หวยเวียดนาม, ML : หวยมาเลย์ (Magnum4D)
      // LottoSubHead:String,
      // STH = STH1 : หวยหุ้นไทย (เที่ยง), STH2 : หวยหุ้นไทย (บ่าย]), STH3 : หวยหุ้นไทย (เย็น),
      // HN = HNST : หวยฮานอย, HNSP : ฮานอยพิเศษ, HNVIP : ฮานอย วีไอพี Hanoi Vip
      // HNRB = HNR : ฮานอย แดง, HNB : ฮานอย ดำ,
      // VT = VTST : เวียดนาม, VTHCM : โฮจิมินห์ Ho Chi Minh,  VTVIP : เวียดนาม VIP
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then(response => {
              const userData = response.data
              useJwt.setToken(response.data.token)
              useJwt.setRefreshToken(response.data.refreshToken)
              localStorage.setItem('userData', JSON.stringify(userData))
              localStorage.setItem('UserToken', userData.UserToken)
              // localStorage.setItem('LottoList', JSON.stringify(this.LottoList))
              this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              this.$router
                .push(getHomeRouteForLoggedInUser(userData.role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Welcome to Image Dashboard',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'You have successfully logged ',
                    },
                  })
                })
                .catch(error => {
                  this.$refs.loginForm.setErrors(error.response.data.error)
                })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'ERROR Email or Password incorrect',
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: error,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style scoped>
.bg {
  background: #212534;
}
.card {
    border-radius: 14px !important;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1) !important;
    background-color: rgba(0, 0, 0, 0.774) !important;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
